$tablet: 780px;
$desktop: 781px;
$landscapePhone: 480px;
$verticalTablet: 1000px;

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin landscape {
  @media (min-width: #{$landscapePhone}) and (max-width: #{$tablet}){
    @content;
  }
}

@mixin verticalTablet {
  @media (min-height: #{$verticalTablet}){
    @content;
  }
}
