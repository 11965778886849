@import "./colors.scss";
@import "./mixins.module.scss";

.pageContainer {
    
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    font-family: "Lato", sans-serif;
    position: relative;
    align-items: center;
    padding-top: 6rem;
    min-height: 75vh;
    padding-bottom: 12rem;

    a{
        color: white;
        text-decoration: none;

        &:hover{
            color: $orange;
            transition: 150ms ease-in-out all;
        }
    }

    .backgroundImage {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -100;
        .overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.5;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include tablet{
                object-position: right;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        padding: 1rem;

        
    }
}
