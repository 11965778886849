@import "../../colors.scss";

.singlePostContainer {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 15px $purple;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;

    .date {
        padding: 1rem;
        padding-bottom: 0;
        font-size: 0.7rem;
        color: $purple;
    }
    .title {
        padding: 0 1rem;
    }
    .content {
        padding: 0 1rem;
    }

    .photosContainer {
        width: 100%;
        height: 100%;

        .imageContainer {
            width: 100%;
            max-height: 500px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .pagination {
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            padding: 1rem;
            flex-wrap: wrap;

            svg:hover {
                cursor: pointer;
                fill: $warmOrange;
            }
        }
    }
}
