@import "../../mixins.module.scss";
@import "../../colors.scss";

.contactContainer {
    width: 100%;
    h1 {
        font-family: "Allura", cursive;
        color: white;
        font-weight: 300;
        font-size: 3rem;
        text-align: center;
    }

    .contactTypesContainer {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        flex-wrap: wrap;
        .formContainer {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            width: 100%;
            margin: 1rem;
            max-width: 450px;
            padding: 1rem;
            background: linear-gradient(225deg, rgba(0, 0, 0, 0) 2rem, white 2rem, white 60%, rgba(0, 0, 0, 0) 90%),
                linear-gradient(45deg, rgba(0, 0, 0, 0) 2rem, white 2rem, white 60%, rgba(0, 0, 0, 0) 90%);

            h4 {
                font-weight: 300;
                font-size: 1.5rem;
                margin: 0;
            }

            .otherContactTypes {
                flex-grow: 1;
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
            }
            .contactType {
                padding: 1rem 0;

                a {
                    display: flex;
                    display: -webkit-flex;
                    align-items: flex-end;
                    color: $orange;
                    font-size: 1.25rem;

                    &:hover {
                        color: $purple;
                        transition: 150ms ease-in-out all;
                    }

                    svg {
                        margin-right: 0.25rem;
                    }
                }
            }

            form {
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                align-items: flex-end;
                button {
                    align-self: flex-end;
                    width: fit-content;
                    padding: 0.5rem 0.75rem;
                    border-radius: 5px;
                    border-width: 0px;
                    background-color: $violet;
                    color: white;
                    outline: none;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin-right: 0.25rem;
                    }

                    &:hover {
                        background-color: lighten($violet, 10%);
                        transition: 150ms ease-in-out all;
                    }

                    &:active {
                        transform: scale(1.1);
                    }
                }
            }

            .formElement {
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                margin: 1.5rem 0;
                width: 100%;
                input,
                textarea {
                    border-width: 0;
                    border-bottom: 1px solid $orange;
                    outline: none;
                }
                input:active,
                input:focus {
                    border-bottom: 1px solid $violet;
                    box-shadow: 0 1px 0px $violet;
                }

                textarea {
                    resize: none;
                    border: 1px solid $orange;
                }
                textarea:focus,
                textarea:active {
                    border: 1px solid $violet;
                    box-shadow: 0 2px 0px $violet;
                }

                label {
                    font-size: 0.9rem;
                    margin-top: 0.25rem;
                }
            }
        }
    }

    .logosContainer {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        padding: 1rem;

        .imageContainer {
            margin: 1rem;
            width: 45%;
            max-width: 200px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
