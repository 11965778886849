@import "../../colors.scss";
@import "../../mixins.module.scss";

.navbarContainer {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    z-index: 2;

    &:hover {
        .navbarBackground {
            opacity: 1 !important;
            @include desktop {
                transition: all 200ms ease-in-out;
            }
        }
    }
    .navbarBackground {
        z-index: 1;
        background-color: $violet;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 3px $violet;
    }
    .logo {
        font-family: "Allura", cursive;
        font-size: 1.4rem;
        align-self: flex-start;
        margin: 0;
    }
    .leftSide,
    .rightSide {
        z-index: 3;
        padding: 0 2rem;
    }

    .leftSide {
        @include tablet {
            padding: 0;
        }
    }

    .rightSide {
        .full {
            @include tablet {
                display: none;
            }
        }

        .burgerMenu {
            display: none;
            @include tablet {
                display: block;
            }

            .opened {
                position: absolute;
                left: 0;
                bottom: -2rem;
                background-color: $violet;
                width: 100%;
                padding: 1rem 0;
                transition: all 150ms ease-in-out;
            }
        }
    }

    a {
        margin: 0 2rem;
        text-decoration: none;
        color: white;
        z-index: 3;
        outline: none;
        text-shadow: 0 1px 3px #000;

        &:hover {
            color: $orange;
        }
    }
}
