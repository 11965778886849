@import "../../colors.scss";
@import "../../mixins.module.scss";

.aboutPageContainer {
    width: 100%;

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 4;
        }

        .dogModal {
            background-color: white;
            box-shadow: 0 0 3px $orange;
            border-radius: 5px;
            padding: 1rem 0rem;
            z-index: 6;
            overflow-y: auto;
            position: relative;
            @include tablet {
                position: absolute;
                top: 0;
            }
            @include verticalTablet{
                position: relative;
                top: none;
            }

            .close {
                position: absolute;
                top: 1rem;
                right: 1rem;

                &:hover {
                    cursor: pointer;
                    color: $orange;
                    transition: all 150ms ease-in-out;
                }
            }
        }
    }

    .upperCover {
        margin-top: 40vh;
        @include tablet {
            margin-top: 20vh;
        }

        width: 100%;
        height: 30rem;
        position: relative;
        overflow: hidden;
        z-index: -1;
        margin-bottom: -15.1rem;
        &:before {
            @include landscape {
                display: none;
            }
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            background: radial-gradient(ellipse, rgba(0, 0, 0, 0) 71%, white 73%);
            @include tablet {
                background: radial-gradient(circle, rgba(0, 0, 0, 0) 70%, white 72%);
            }
            transform: translateY(-50%);
        }
        @include landscape {
            margin-bottom: 0;
            height: 2rem;
        }

        @include verticalTablet {
            height: 30rem;
        }
    }

    .bottomCover {
        position: relative;
        top: -1px;
        background: linear-gradient(170deg, white 50%, rgba(0, 0, 0, 0) 52%);
        height: 20rem;
        margin-bottom: 70vh;
    }

    .content {
        box-shadow: 0 -2px 7px white;
        background-color: white;
        padding: 4rem;
        display: flex;
        display: -webkit-flex;
        color: #333;
        flex-direction: column;
        align-items: center;
        @include tablet {
            padding: 2rem;
        }

        h1 {
            font-family: "Allura", cursive;
            font-size: 3rem;
            text-align: center;
            margin-bottom: 1rem;
        }

        h4 {
            font-weight: 300;
            font-size: 1.2rem;
        }

        .sectionContainer {
            width: 100%;
            h4 {
                font-family: "Allura", cursive;
                font-size: 2.4rem;
                margin-bottom: 0;
            }

            .subheading {
                font-weight: 300;
                font-size: 1.2rem;
            }
            .ourDogs {
                margin-top: 1.33rem;
                display: flex;
                display: -webkit-flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .dogCard {
                    width: 45%;
                    @include tablet {
                        width: 100%;
                    }
                    position: relative;
                    border-radius: 10px;
                    overflow: hidden;
                    margin: 1rem 0rem;

                    &:hover {
                        cursor: pointer;

                        img {
                            transform: scale(1.1);
                            transition: 150ms ease-in-out all;
                        }
                    }

                    .imgContainer {
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        position: relative;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    .description {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 1rem 1rem;
                        border-top-right-radius: 10px;
                        background-color: $violet;
                        color: white;
                    }

                    .dogName,
                    .dogBreed {
                        font-size: 1.1rem;
                    }

                    .dogName {
                        font-weight: 300;
                    }

                    .dogBreed {
                        font-weight: 700;
                    }
                }
            }

            .aboutUsContainer {
                display: flex;
                display: -webkit-flex;
                margin: 2rem 0;
                @include tablet {
                    flex-direction: column;
                }
                .divider {
                    margin: 0 1rem;
                    width: 0px;
                    border-left: 1px solid $violet;
                    @include tablet {
                        display: none;
                    }
                }

                .person {
                    flex: 1;
                    display: flex;
                    display: -webkit-flex;
                    flex-direction: column;
                    padding: 2rem 1rem;
                    position: relative;
                    &:first-child {
                        text-align: right;
                        &::before {
                            content: "";
                            width: 25%;
                            border-top: 1px solid $orange;
                            position: absolute;
                            top: 0.75rem;
                            right: -5%;
                        }
                    }
                    &:last-child {
                        align-self: flex-end;
                        &::before {
                            content: "";
                            width: 25%;
                            border-top: 1px solid $warmOrange;
                            position: absolute;
                            top: 0.75rem;
                            left: -5%;
                        }
                    }
                    .name {
                        font-size: 1.3rem;
                        text-transform: uppercase;
                    }
                    .personDescription {
                        p {
                            line-height: 1.35rem;
                        }
                    }
                }
            }

            .extraFeaturesContainer {
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                margin: 2rem 0;
                .extraFeaturesRow {
                    display: flex;
                    display: -webkit-flex;
                    width: 100%;
                    justify-content: center;
                    &:nth-child(even) {
                        border-top: 1px solid $violet;
                    }

                    @include tablet {
                        flex-direction: column;
                        align-items: center;
                    }
                    .extraFeature {
                        display: flex;
                        display: -webkit-flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 2rem;
                        max-width: 500px;
                        &:nth-child(odd) {
                            border-right: 1px solid $violet;
                            @include tablet {
                                border-right-width: 0px;
                                border-bottom: 1px solid $violet;
                            }
                        }
                        .heading {
                            font-weight: 700;
                            margin: 0.5rem;
                            font-size: 1.1rem;
                        }
                        .featureDescription {
                            line-height: 1.35rem;
                            font-weight: 300;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
